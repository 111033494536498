var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-window-wrap" },
    [
      _c("div", { staticClass: "info-title" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        { staticClass: "info-cont" },
        _vm._l(_vm.list, function (val, index) {
          return _c("p", { key: "info_" + index }, [
            _vm._v(" " + _vm._s(val) + " "),
          ])
        }),
        0
      ),
      _vm.btnText
        ? _c(
            "Button",
            { staticClass: "info-btn", on: { click: _vm.doSubmit } },
            [
              _c("img", {
                staticClass: "btn-pic",
                attrs: { src: _vm.logopng, alt: "" },
              }),
              _vm._v(" " + _vm._s(_vm.btnText) + " "),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }