<template>
  <div class="info-window-wrap">
    <div class="info-title">{{ title }}</div>
    <div class="info-cont">
      <p v-for="(val, index) in list" :key="'info_' + index">
        {{ val }}
      </p>
    </div>
    <Button v-if="btnText" class="info-btn" @click="doSubmit">
      <img class="btn-pic" :src="logopng" alt="" />
      {{ btnText }}
    </Button>
  </div>
</template>

<script>
import "style/infowindow.less";
import logopng from "@/assets/images/icon_logo_mini.png";
export default {
  props: ["title", "list", "btnText"],
  data() {
    return {
      logopng: logopng,
    };
  },
  methods: {
    doSubmit() {
      this.$emit("doSubmit");
    },
  },
};
</script>
