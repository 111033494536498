import { User } from "@/service/user";
import { formatFieldsList, login, openNewWindow } from "js/utils.js";
const collectMixin = {
  computed: {
    isUserLogin() {
      return this.$store.state.isUserLogin;
    },
  },
  methods: {
    // 收藏相关
    hideCollectList() {
      if (this.isShowCollection) {
        this.isShowCollection = false;
        this.initTable();
      }
    },
    // 我的收藏
    changeCollectShow() {
      if (!this.checkLogin()) {
        return;
      }
      this.getCollectList();
      this.isShowCollection = true;
    },
    // 获取收藏列表
    async getCollectList() {
      let params = {
        sort: this.sort,
        page: 1,
        pageSize: 1000,
      };
      this.isLoading = true;
      let res = await User.getCollectionList(params);
      if (res && res.code === 200) {
        let list = res.data.items;
        list = list.map((item) => {
          item.isCollect = true;
          return item;
        });
        if (this.formatCollectList) {
          list = await this.formatCollectList(list);
        }
        this.collectList = list;
      }
      this.isLoading = false;
    },
    // 添加收藏
    async addCollect(code) {
      if (!this.checkLogin()) {
        return;
      }
      let params = {
        sort: this.sort,
        code: code,
      };
      let res = await User.addCollection(params);
      if (res && res.code === 200) {
        this.$Message.success((res && res.msg) || "收藏成功");
        if (this.isLocation) {
          this.checkLocationCollect();
          return;
        }
        if (this.isShowCollection) {
          this.getCollectList();
        } else {
          this.initTable();
        }
      }
    },
    // 取消收藏
    async delCollect(code) {
      if (!this.checkLogin()) {
        return;
      }
      this.$global.confirm({
        title: "提示",
        content: "确认要取消收藏吗？",
        ok: async () => {
          let params = {
            sort: this.sort,
            code: code,
          };
          let res = await User.deleteCollection(params);
          if (res && res.code === 200) {
            this.$Message.info((res && res.msg) || "取消成功");
            if (this.isLocation) {
              this.checkLocationCollect();
              return;
            }
            if (this.isShowCollection) {
              this.getCollectList();
            } else {
              this.initTable();
            }
          }
        },
      });
    },
    // 检测列表中收藏状态
    async checkListCollectStatus(_list) {
      let list = _list || [];
      if (this.isUserLogin) {
        let codes = list.map((item) => item.code);
        let res2 = await User.checkCollectStatus(this.sort, codes);
        if (res2 && res2.code === 200) {
          let checkList = res2.data.items || [];
          for (let i = 0, n = list.length; i < n; i++) {
            let item = list[i];
            let isCollect = checkList[i] && checkList[i].isExist;
            item.isCollect = isCollect;
            list[i] = item;
          }
        }
      }
      return list;
    },
    checkLogin() {
      if (!this.isUserLogin) {
        this.$global.confirm({
          title: "提示",
          content: "请先登录",
          ok: () => {
            login();
          },
        });
        return false;
      }
      return true;
    },
  },
};
export default collectMixin;
