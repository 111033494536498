<template>
  <Poptip
    transfer
    placement="bottom-start"
    popper-class="poptip-table-wrap"
    v-model="isOpenFilterPanel"
    class="poptip-btn-wrap"
  >
    <div
      class="filter-title"
      :class="{ active: isOpenFilterPanel, selected: isHaveFilter }"
    >
      <slot></slot>
      <template v-if="title">
        {{ title }}
        <Icon type="md-arrow-dropdown" class="arrow-icon" />
      </template>
    </div>
    <div class="table-wrap" slot="content">
      <table class="filter-items-table" v-if="!isIndustry">
        <tr v-for="(pItem, i) in data.filters" :key="'filters_' + i">
          <td class="title">
            <!-- <i class="icon-vip filter-vip-icon" v-if="pItem.isVip"></i> -->
            <Icon type="ios-lock-outline filter-vip-icon"  v-if="pItem.isVip"/>
            {{ pItem.label }}
          </td>
          <td class="value-list">
            <span
              v-for="(item, index) in pItem.submenu"
              :key="'item_' + i + '_' + index"
              class="select-item"
              :class="{ active: checkFilterCur(pItem, item) }"
              @click="selectMenuFilter(pItem, item)"
              >{{ item.label }}</span
            >
          </td>
        </tr>
      </table>
      <table class="filter-items-table" v-if="isIndustry">
        <tr v-for="(pItem, i) in data.filters" :key="'filters_' + i">
          <td class="title">{{ pItem.label }}</td>
          <td class="value-list">
            <span
              v-for="(item, index) in pItem.submenu"
              :key="'item_' + i + '_' + index"
              class="select-item"
              :class="{ active: checkFilterCur({ key: data.key }, item) }"
              @click="selectCateFilter(item)"
              >{{ item.label }}</span
            >
          </td>
        </tr>
      </table>
    </div>
  </Poptip>
</template>

<script>
import filter from "./filter";
/**
 * 筛选弹窗
 * 传入: @data = {
 *  filters: [
 *    {
 *      label: '行业',
 *      key: 'industry',
 *      submenu: [
 *        {label: '不限', value: '0'},
 *        {label: '餐饮', value: '1'},
 *      ]
 *    }
 *  ]
 * }
 * @selectFilter 传出: {
 *  industry: []
 * }
 */
export default {
  props: [
    "data",
    "filterData",
    "title",
    "isMulti",
    "isIndustry",
    "isSelectClose",
  ],
  data() {
    return {
      isOpenFilterPanel: false,
      isCommonFilter: true,
      // filterValue: {},
      // filterValueStr: "",
    };
  },
  computed: {
    isHaveFilter() {
      return this.filterValueStr && this.filterValueStr !== "{}";
    },
  },
  mixins: [filter],
  methods: {
    // initData() {
    //   for (let i = 0, n = this.data.filters.length; i < n; i++) {
    //     let filter = this.data.filters[i];
    //     let key = filter.key;
    //     this.$set(this.filterValue, key, []);
    //   }
    //   this.checkSelectValues();
    // },
    closeFilterPanel() {
      // this.isOpenFilterPanel = false;
    },
    selectMenuFilter(pItem, item) {
      if (pItem.isVip) {
        if (!this.$global.checkVIP()) {
          this.$global.gotoVIP();
          // this.isOpenFilterPanel = false;
          return;
        }
      }
      this.selectFilter(pItem, item);
      if (this.isSelectClose) {
        // this.isOpenFilterPanel = false;
      }
    },
    // selectCateFilter(item) {
    //   let key = this.data.key;
    //   if (item.label === "不限") {
    //     this.filterValue[key] = [];
    //   } else {
    //     this.filterValue[key] = [item.value];
    //   }
    //   this.$set(this.filterValue, key, this.filterValue[key]);
    //   this.checkSelectValues();
    //   this.$emit("selectFilter", this.filterValue);
    // },
    // checkFilterCur(pItem, item) {
    //   let key = pItem.key;
    //   let value = item.value;
    //   let selectValues = this.filterValue[key] || [];
    //   if (!value || item.label === "不限") {
    //     return selectValues.length === 0;
    //   }
    //   return selectValues.includes(value);
    // },
    // selectFilter(pItem, item) {
    //   let key = pItem.key;
    //   let value = item.value;
    //   if (!value || item.label === "不限") {
    //     this.filterValue[key] = [];
    //   } else {
    //     if (this.isMulti) {
    //       this.filterValue[key].push(value);
    //     } else {
    //       this.filterValue[key] = [value];
    //     }
    //   }
    //   this.$set(this.filterValue, key, this.filterValue[key]);
    //   this.checkSelectValues();
    //   this.$emit("selectFilter", this.filterValue);
    // },
    // checkSelectValues() {
    //   let obj = {};
    //   for (let key in this.filterValue) {
    //     let values = this.filterValue[key];
    //     if (values.length > 0) {
    //       obj[key] = values;
    //     }
    //   }
    //   this.filterValueStr = JSON.stringify(obj);
    //   return obj;
    // },
  },
  mounted() {
    // this.initData();
  },
};
</script>

<style lang="less">
@import url("style/config.less");
.filter-title {
  display: inline-block;
  color: #333;
  &.active,
  &.selected,
  &:hover {
    color: @focusColor;
  }
}
.poptip-btn-wrap {
  width: 100%;
  .ivu-poptip-rel,
  .filter-title {
    width: 100%;
  }
}
.table-wrap {
  border-radius: 0 0 2px 2px;
  padding: 0 16px 16px;
  max-height: calc(100vh - 200px);
  overflow: auto;
  padding: 4px 0;
  background: rgba(255, 255, 255, 0.99);
  box-shadow: 0px 2px 17px 6px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(16px);
  .filter-items-table {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    .title {
      text-align: right;
      vertical-align: top;
      padding: 5px;
      font-weight: 500;
      .filter-vip-icon {
        width: 24px;
        height: 12px;
        // margin-right: 2px;
        display: inline-block;
        // vertical-align: middle;
        background-size: 100% 100%;
        font-size: 16px;
      }
    }
    .value-list {
      max-width: 400px;
      vertical-align: top;
    }
  }
}
.poptip-table-wrap {
  .ivu-poptip-arrow {
    // left: 0px !important;
  }
  .ivu-poptip-inner {
    // box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.12);
  }
  .ivu-poptip-body {
    padding-bottom: 12px;
  }
}
.select-item {
  float: left;
  color: #7a838b;
  padding: 4px 8px;
  line-height: 1;
  border-radius: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  word-break: keep-all;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  &:not(:last-of-type) {
    margin-right: 8px;
  }
  &:hover {
    color: #000;
  }
  &.active {
    color: @focusColor;
  }
}
</style>
