const filterMixin = {
  data() {
    return {
      filterValue: {},
      filterValueStr: "",
    };
  },

  watch: {
    filterData: {
      handler() {
        this.getFilterValue();
      },
      deep: true,
    },
  },
  methods: {
    // initData() {
    //   console.log(this.$option)

    //   let tempMenu = this.data ? this.data.filters : this.filterMenusData;
    //   for (let i = 0, n = tempMenu.length; i < n; i++) {
    //     let filter = tempMenu[i];
    //     let key = filter.key;
    //     this.$set(this.filterValue, key, []);
    //   }
    //   this.checkSelectValues();
    // },

    selectFilter(pItem, item) {
      let key = pItem.key;
      let value = item.value;
      let tempData = JSON.parse(JSON.stringify(this.filterValue));
      if (!value || item.label === "不限") {
        tempData[key] = [];
      } else {
        if (this.isMulti) {
          tempData[key].push(value);
        } else {
          tempData[key] = [value];
        }
      }
      this.checkSelectValues();
      this.$emit("selectFilter", tempData);
    },
    selectCateFilter(item) {
      let key = this.data ? this.data.key : "industry";
      let tempData = JSON.parse(JSON.stringify(this.filterValue));
      if (item.label === "不限") {
        tempData[key] = [];
      } else {
        tempData[key] = [item.value];
      }
      this.checkSelectValues();
      this.$emit("selectFilter", tempData);
      if (this.isSelectClose) {
        this.isOpenFilterPanel = false;
      }
    },
    getFilterValue() {
      for (let key in this.filterData) {
        this.$set(this.filterValue, key, this.filterData[key]);
      }
      this.checkSelectValues();
    },
    checkSelectValues() {
      let obj = {};
      let filters = (this.data && this.data.filters) || this.moreMenusData;
      let keys = filters.map((item) => item.key);
      if (this.isIndustry) {
        keys = [this.data.key];
      }
      for (let key in this.filterValue) {
        if (!keys.includes(key)) {
          continue;
        }
        let values = this.filterValue[key];
        if (values.length > 0) {
          obj[key] = values;
        }
      }
      this.filterValueStr = JSON.stringify(obj);
      return obj;
    },
    checkFilterCur(pItem, item) {
      let key = pItem.key;
      let value = item.value;
      let selectValues = this.filterValue[key] || [];

      if (!value || item.label === "不限") {
        return selectValues.length === 0;
      }
      return selectValues.includes(value);
    },
    checkFilterMenu(pItem, item) {
      if (!pItem.code && !item?.length) {
        return true
      }
      if (item && item.length > 0) {
        for (let i = 0, len = item.length; i < len; i++) {
          if (pItem.level === 1) {
            if (item[i].code.split("_")[0] === pItem.code) {
              return true;
            }
          } else {
            const [pId, id, sId] = item[i].code.split("_")
            if (!pItem.code || pId + '_' + id === pItem.code) {
              return true;
            }
          }
        }
        return false;
      } else {
        return false;
      }
    },
  },
};

export default filterMixin;
