<template>
  <Page
    class="pager-little"
    :total="total"
    :pageSize="pageSize"
    :page="page"
    :show-total="false"
    :show-elevator="false"
    prev-text="上一页"
    next-text="下一页"
    @on-change="handleChange"
  ></Page>
</template>

<script>
export default {
  props: ["total", "pageSize", "page"],
  methods: {
    handleChange(page) {
      this.$emit("on-change", page);
    },
  },
};
</script>

<style lang="less">
.pager-little.ivu-page.mini,
.pager-little {
  height: 40px;
  padding: 8px 10px 12px 10px;
  text-align: right;
  .ivu-page-custom-text {
    border: 1px solid #d8d8d8;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    padding: 0 6px;
    cursor: pointer;
    a {
      font-size: 12px;
    }
  }
  .ivu-page-item,
  .ivu-page-item-jump-next {
    display: none;
  }
  .ivu-page-simple-pager {
    display: none;
  }
}
</style>
