var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", {
    staticClass: "pager-little",
    attrs: {
      total: _vm.total,
      pageSize: _vm.pageSize,
      page: _vm.page,
      "show-total": false,
      "show-elevator": false,
      "prev-text": "上一页",
      "next-text": "下一页",
    },
    on: { "on-change": _vm.handleChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }